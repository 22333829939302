/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { api } from '../../app/config/api';
// import { api } from './authReducer';
import { AppDispatch } from '../types';
import { ResponseList } from '../helpers/axiosResponse';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';

const normalizedPlansData = (
  data: PlanModel[], // This function is used to normalize the data for CSV file
) =>
  data.map((plan: PlanModel) => ({
    id: plan.id,
    name: plan.name,
    price: plan.price,
    itemList: plan.itemList
      .map((item) => `${item.promoItem} (${item.detailedItem})`)
      .join(', '),
  }));

type Item = {
  promoItem: string;
  detailedItem: string;
};

export type PlanModel = {
  id: number;
  name: string;
  price: number;
  itemList: Item[];
};

type state = InitialState<PlanModel[]> & {
  normalizedData: any[];
};

const initialState: state = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
  normalizedData: [],
};

const slice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDataNormalized: (state, action) => {
      state.normalizedData = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, setDataNormalized, onLoading, onSuccess, onError } =
  slice.actions;
export default slice.reducer;

// REPOSITORY //

export type PlanBody = {
  name: string;
  price: number;
  itemList: Item[];
};

export const createPlan =
  (body: PlanModel) => async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());

      const promoItems = body.itemList.map((item) => item.promoItem);
      const detailedItem = body.itemList.map((item) => item.detailedItem);

      const newBody = {
        name: body.name,
        price: body.price,
        promo_items: promoItems,
        detailed_items: detailedItem,
      };

      await api.post<ResponseList>('/plans', newBody);
      dispatch(onSuccess());
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const findAllPlans = () => async (dispatch: AppDispatch) => {
  dispatch(onLoading());

  try {
    const response = await api.get<ResponseList>('/plans');
    const responseData = response.data.body.data;

    const plans: PlanModel[] = responseData.result.map<PlanModel>((data) => ({
      id: data.id,
      name: data.name,
      price: data.price,
      itemList: data.promo_items.map((items: any, index: number) => ({
        promoItem: items,
        detailedItem: data.detailed_items[index],
      })),
    }));

    dispatch(setDataNormalized(normalizedPlansData(plans)));

    dispatch(onSuccess());
    dispatch(setData(plans));
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

export const updatePlan =
  (id: number, body: PlanBody) => async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());

      const promoItems = body.itemList.map((item) => item.promoItem);
      const detailedItem = body.itemList.map((item) => item.detailedItem);

      const newBody = {
        name: body.name,
        price: body.price,
        promo_items: promoItems,
        detailed_items: detailedItem,
      };

      await api.patch<ResponseList>(`/plans/${id}`, newBody);
      dispatch(onSuccess());
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const deletePlan = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(onLoading());
    await api.delete<ResponseList>(`/plans/${id}`);
    dispatch(findAllPlans());
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};
