/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { TableColumn } from 'react-data-table-component';

import { dateFormat, dateTimeFormat } from '../../../utils/date-time-formatter';
import { UserTableModel } from '../../../../store/reducers/tables/usersTableReducer';

export type BookOrdersTableColumns = {
  onClick: (item: UserTableModel) => void;
  PLAN_OPTIONS: any[];
  updatePlan: (
    userId: string,
    oldValue: string,
    value: string,
    expireDate: Date,
  ) => void;
  updatePlanExpireDate: (
    userId: string,
    expireDate: Date,
    isPremium: boolean,
  ) => void;
};

export function makeUsersTableColumns({
  onClick,
  PLAN_OPTIONS,
  updatePlan,
  updatePlanExpireDate,
}: BookOrdersTableColumns): TableColumn<UserTableModel>[] {
  return [
    {
      name: '',
      sortable: false,
      width: '100px',
      selector: (row: any) => row.details,
      cell: (row: any) => (
        <button
          className="py-2 px-3 rounded-md bg-primary-500 hover:bg-primary-600  text-xs text-white font-bold"
          onClick={() => onClick(row)}
          id={row.action}
        >
          Details
        </button>
      ),
    },
    {
      name: 'FIRST NAME',
      sortable: true,
      wrap: true,
      selector: (row) => row.firstName,
      // sortFunction: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      name: 'LAST NAME',
      sortable: true,
      wrap: true,
      width: '140px',
      selector: (row) => row.lastName,
      // sortFunction: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      name: 'BIRTHDAY',
      sortable: true,
      wrap: true,
      width: '140px',
      selector: (row) =>
        !row.birthday || row.birthday.length === 0
          ? 'Not informed'
          : row.birthday,
    },
    {
      name: 'EMAIL',
      sortable: true,
      wrap: true,
      width: '180px',
      selector: (row) => row.email,
      // sortFunction: (a, b) => a.email.localeCompare(b.email),
    },
    {
      name: 'REGISTRATION DATE',
      sortable: true,
      wrap: true,
      width: '170px',
      selector: (row) => dateTimeFormat(row.registrationDate),
    },
    {
      name: 'LAST LOGIN DATE',
      sortable: false,
      wrap: true,
      width: '170px',
      selector: (row) =>
        row.lastLoginDate ? dateTimeFormat(row.lastLoginDate) : '---',
    },
    {
      name: 'ACTIVATED PLAN',
      sortable: false,
      wrap: true,
      width: '200px',
      selector: (row: any) => row.plan,
      cell: (row: any) => (
        <div className="w-full py-1">
          <select
            className="form-select rounded-md block h-[38px] w-full px-1 py-1.5 text-sm bg-white bg-clip-padding bg-no-repeat border-2 border-solid border-gray-300 focus:bg-white hover:border-primary-500 focus:border-primary-500 focus:outline-none"
            aria-label="Select user plan"
            value={row.plan}
            onChange={(value) => {
              updatePlan(
                row.userId,
                row.plan,
                value.target.value,
                row.planExpireDate ? row.planExpireDate : row.freeExpireDate,
              );
            }}
          >
            {PLAN_OPTIONS &&
              PLAN_OPTIONS.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name === 'Free plan'
                    ? 'Unverified account'
                    : option.name}
                </option>
              ))}
          </select>
        </div>
      ),
    },
    {
      name: 'PLAN EXPIRE DATE',
      sortable: false,
      wrap: true,
      width: '150px',
      cell: (row: any) => {
        let color = 'text-black-text';

        if (row.planExpireDate || row.freeExpireDate) {
          const expireDate = row.planExpireDate || row.freeExpireDate;
          if (new Date(expireDate) < new Date()) {
            color = 'text-red-500';
          }
        }

        return (
          <div>
            <button
              onClick={() =>
                updatePlanExpireDate(
                  row.userId,
                  row.planExpireDate ? row.planExpireDate : row.freeExpireDate,
                  // eslint-disable-next-line no-unneeded-ternary
                  row.activatedPlan !== null ? true : false,
                )
              }
            >
              <p className={color}>
                {row.planExpireDate
                  ? dateFormat(row.planExpireDate)
                  : row.freeExpireDate
                  ? dateFormat(row.freeExpireDate)
                  : '---'}
              </p>
            </button>
          </div>
        );
      },
    },
    {
      name: 'WHY ARE YOU WRITING',
      sortable: false,
      wrap: true,
      width: '210px',
      selector: (row: any) => row.whyWritting,
    },
    {
      name: 'TOTAL IMAGES',
      sortable: false,
      wrap: true,
      width: '160px',
      selector: (row) => row.totalImages,
      // sortFunction: (a, b) => a.totalImages - b.totalImages,
    },
    {
      name: 'T. ANSWERED QUESTIONS',
      sortable: false,
      wrap: true,
      width: '200px',
      selector: (row) => row.totalAnsweredQuestions,
      // sortFunction: (a, b) =>
      // a.totalAnsweredQuestions - b.totalAnsweredQuestions,
    },
  ];
}
